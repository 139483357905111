if (!process.env.API_URL) {
  throw new Error('MissingEnv API_URL');
}

if (!process.env.KEYCLOAK_CLIENT_ID) {
  throw new Error('MissingEnv KEYCLOAK_CLIENT_ID');
}

export const config = {
  apiUrl: process.env.API_URL,
  keycloakClientId: process.env.KEYCLOAK_CLIENT_ID,
  defaultMapPosition: {
    lat: 54.6195719,
    lng: -1.0669469,
  },
  defaultMapZoom: 14,
};